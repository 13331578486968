<template>
  <modify-apply-block
    data-type="train"
    ref="train_p"
    :getEdit="getEdit"
    :saveEdit="saveEdit"
    :addEdit="addEdit"
    :form-parms-add="formParmsAdd"
    :dataFileParm="{docName: '培训与进修信息', isDoc: true}"
    :onChange="onFormChange"
    :getTitle="getTitle"
    block-name="培训与进修信息"
    ref-name="train"
    :worker-id="workerId">
  </modify-apply-block>
</template>

<script>
import ModifyApplyBlock from './modifyApplyBlock'

import {
  trainRequest
} from '@/api'

export default {
  components: {
    ModifyApplyBlock
  },
  data () {
    return {
      workerId: null
    }
  },
  created () {
    this.workerId = this.$router.history.current.query.workerId
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs['train_p'].openForm) {
      this.$refs['train_p'].openForm = false
      next(false)
    } else {
      next()
    }
  },
  methods: {
    onFormChange (key, value, data) {
      if (key === 'startDate' || key === 'endDate') {
        if (data.startDate && data.endDate) {
          let day = (new Date(data.endDate) - new Date(data.startDate)) / 1000 / 60 / 60 / 24 + 1
          return {
            dayNum: day
          }
        }
      }
    },
    async getEdit () {
      if (this.workerId) {
        return await trainRequest.getEdit({
          workerId: this.workerId
        })
      }
    },
    async saveEdit (parm) {
      return await trainRequest.saveEdit(Object.assign({}, parm, {
        dataId: parm.id,
        id: undefined
      }))
    },
    async addEdit (parm) {
      return await trainRequest.addEdit(parm)
    },
    getTitle (data) {
      return (data.zbdw || '') + ' ' + (data.startDate ? data.startDate.slice(0, 10) : '')
    }
  },
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '培训主办单位名称',
          key: 'zbdw'
        },
        {
          type: 'select',
          label: '培训类型',
          selectDatas: this.$store.getters.selectDatas['train_type_list'],
          key: 'type',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '出国（出境）培训标识',
          selectDatas: ['是', '否'].map(v => {return {key: v, label: v}}),
          key: 'cg',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '教育培训性质',
          selectDatas: this.$store.getters.selectDatas['train_jypx_list'],
          key: 'jypx',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '培训项目名称',
          key: 'pxxm'
        },
        {
          type: 'select',
          selectDatas: this.$store.getters.selectDatas['train_lg_list'],
          label: '培训离岗状态',
          key: 'lg',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '从学单位类别',
          selectDatas: this.$store.getters.selectDatas['train_cxdw_list'],
          key: 'cxdw',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '培训起始日期',
          key: 'startDate',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '培训结束日期',
          key: 'endDate'
        },
        {
          type: 'input',
          label: '培训天数',
          key: 'dayNum',
          check: {
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    }
  }
}
</script>
